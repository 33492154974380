<template>
    <section>
        <GoBack @on-leave="resetSelectedActivityGroup" class="mb-3" />

        <div v-if="!selectedActivityGroup">
            <b-loading is-full-page v-model="isLoading"></b-loading>
        </div>

        <div v-else>
            <b-notification v-if="!hasEnoughSeats.hasEnoughSeats" type="is-danger" aria-close-label="Close notification" role="alert">
                This activity doesn't have enough seats for the number of participants. We still need to seat <strong>{{ hasEnoughSeats.numberOfMissingSeats }}</strong> participants.
            </b-notification>

            <p class="has-text-weight-semibold mt-2">{{ getActivityInfo(selectedActivityGroup.activityId).title || 'Unknown'
            }}
            </p>
            <div class="container--info mt-5 mb-5">
                <div class="columns">
                    <div class="column is-6">
                        <div class=" display-flex-space-btw">
                            <p class="has-text-weight-medium">Group id</p>
                            <div class="display-flex-space-btw">
                                <p class="activity-details">{{ selectedActivityGroup.id || 'Unknown' }}</p>
                                <div @click="copyTextToClipboard(selectedActivityGroup.id)">
                                    <b-icon :class="{ 'is-invisible': !selectedActivityGroup.id }" pack="far"
                                        class="ml-2 icon--light-grey" icon="copy"></b-icon>
                                </div>
                            </div>
                        </div>

                        <div class=" display-flex-space-btw">
                            <p class="has-text-weight-medium">Date</p>
                            <div class="display-flex-space-btw">
                                <p class="activity-details">{{ dateWithName(selectedActivityGroup.date) || 'Unknown' }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="column is-6">
                        <div class="display-flex-space-btw">
                            <p class="has-text-weight-medium">Start time</p>
                            <div class="display-flex-space-btw">
                                <p class="activity-details">{{ selectedActivityGroup.time || 'Unknown' }}</p>
                            </div>
                        </div>
                        <div class="display-flex-space-btw">
                            <p class="has-text-weight-medium">End time</p>
                            <div class="display-flex-space-btw">
                                <p class="activity-details">{{ selectedActivityGroup.endTime || 'Unknown' }}</p>
                            </div>
                        </div>

                    </div>
                    <div class="column is-6">
                        <div class="display-flex-space-btw">
                            <p class="has-text-weight-medium">Number of participants</p>
                            <div class="display-flex-space-btw">
                                <p class="activity-details">{{ selectedActivityGroup.groupSize || 'Unknown' }}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <b-tabs v-model="activeTab">
                <b-tab-item label="Participants">
                    <b-table class="mt-4" :data="selectedActivityGroup.participants" ref="table" paginated per-page="5"
                        aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                        aria-current-label="Current page" pagination-rounded>

                        <b-table-column field="clientName" label="Participant name" sortable v-slot="props">
                            {{ props.row.clientName }}
                        </b-table-column>

                        <b-table-column field="isPrivateTour" label="Tour type" sortable v-slot="props">
                            <b-tag v-if="props.row.isPrivateTour" icon="lock"  type="is-info is-light">Private tour</b-tag>
                            <b-tag v-else icon="user-friends" type="is-primary is-light">Shared tour</b-tag>
                        </b-table-column>

                        <b-table-column field="tickets" label="N. of tickets" sortable v-slot="props">
                            <span>
                                {{ props.row.tickets.length }}
                            </span>
                        </b-table-column>

                        <template #empty>
                            <div class="has-text-centered">This activity doesn't have any participants</div>
                        </template>
                    </b-table>

                </b-tab-item>

                <b-tab-item label="Guiders">
                    <b-button class="mt-4 mb-2" @click="updateGuidersStatuses" type="is-primary" icon-left="save"
                        :disabled="hasChanges">Save
                        changes</b-button>
                    <b-table class="mt-4" :data="selectedActivityGroup.guiders" ref="table" paginated per-page="5"
                        aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                        aria-current-label="Current page" pagination-rounded>

                        <b-table-column field="guiderName" label="Name" sortable v-slot="props">
                            {{ props.row.name }}
                        </b-table-column>

                        <b-table-column field="nOfSeats" label="N. of seats" sortable v-slot="props">
                            <span>
                                {{ props.row.nOfSeats }}
                            </span>
                        </b-table-column>

                        
                        <b-table-column field="numberOfVehicles" label="N. of vehicles" sortable v-slot="props">
                            <span>
                                {{ props.row.numberOfVehicles }}
                            </span>
                        </b-table-column>

                        <b-table-column field="status" label="Status" sortable v-slot="props">
                            <b-select :class="getStatusClass(props.row.status)" placeholder="Select a status" rounded
                                v-model="props.row.status" size="is-small">
                                <option v-for="status in GuiderReportStatuses " :key="status.id" :value="status.id">
                                    {{ status.name }}</option>

                            </b-select>
                        </b-table-column>

                        <b-table-column field="notes" label="Notes" v-slot="props">
                            <b-input expanded v-model="props.row.notes" maxlength="700" type="textarea"></b-input>
                        </b-table-column>

                        <template #empty>
                            <div class="has-text-centered">This activity doesn't have any guider yet</div>
                        </template>
                    </b-table>
                </b-tab-item>
            </b-tabs>
        </div>
    </section>
</template>


<script>
import { mapState, mapActions, mapMutations } from "vuex";
import GoBack from "../components/elements/GoBack.vue";
import { ActivitiesActions, ActivitiesMutations, ActivitiesStates } from '../store/Storetypes'
import { dateWithName } from '../helpers';
import { ActivityMixin, HelpersMixin } from "../mixins";
import { GuiderReportStatuses } from '../enums/GuiderReportStatuses'
import { isEqual } from 'lodash'
import { db } from "../firebase";
export default {
    components: {
        GoBack
    },
    mixins: [ActivityMixin, HelpersMixin],
    data() {
        return {
            activeTab: 0,
            dateWithName,
            GuiderReportStatuses,
            initialActivityGroupData: undefined
        }
    },
    computed: {
        ...mapState({
            selectedActivityGroup: state => state.activities[ActivitiesStates.SELECTED_ACTIVITY_GROUP_DATA],
        }),
        hasChanges() {
           return isEqual(this.initialActivityGroupData.guiders, this.selectedActivityGroup.guiders)
        },
        isLoading() {
            return !this.selectedActivityGroup
        },
        hasEnoughSeats(){
            if (!this.selectedActivityGroup.guiders || !this.selectedActivityGroup.guiders.length) return  {hasEnoughSeats: false, numberOfMissingSeats: this.selectedActivityGroup.groupSize }
            let nOfSeats = 0
            this.selectedActivityGroup.guiders.map((guider)=> nOfSeats+= guider.nOfSeats)
            return {hasEnoughSeats: this.selectedActivityGroup.groupSize <= nOfSeats, numberOfMissingSeats: this.selectedActivityGroup.groupSize - nOfSeats }
        }
    },
    created() {
        const activityGroupId = this.$route.params.activityGroup
        //if (this.selectedActivityGroup?.id !== activityGroupId || !this.selectedActivityGroup?.id)
        this.getActivityGroupData(activityGroupId)
        this.initialActivityGroupData = { ...this.selectedActivityGroup }
    },
    methods: {
        ...mapActions({
            getActivityGroupData: ActivitiesActions.GET_ACTIVITY_GROUP_DATA,
        }),
        ...mapMutations({
            setSelectedActivityGroupData: ActivitiesMutations.SET_SELECTED_ACTIVITY_GROUP_DATA,
        }),
        copyTextToClipboard(text) {
            if (!text) return
            this.copyToClipboard(text)
        },
        getStatusClass(statusId) {
            if (!this.GuiderReportStatuses[statusId].class) return
            return this.GuiderReportStatuses[statusId].class
        },
        updateGuidersStatuses() {
            if (isEqual(this.initialActivityGroupData.guiders, this.selectedActivityGroup.guiders)) return
            this.updateGuidersStatusesInDB(this.selectedActivityGroup.id,
                this.selectedActivityGroup.guiders
            )
        },
        resetSelectedActivityGroup() {
            this.setSelectedActivityGroupData(undefined)
        },
        updateGuidersStatusesInDB(groupId, guiders) {
            db.firestore()
                .collection("groups")
                .doc(groupId)
                .update({ guiders })
                .then(() => {
                    this.initialActivityGroupData = { ...this.selectedActivityGroup }
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: 'Changes saved',
                        position: "is-bottom",
                        type: "is-success",
                    });
                })
                .catch((err) => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: err.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.activity-details {
    text-align: start;
    color: #373F4F;
    font-weight: 300;
}

.container--info {
    display: block;
    padding: 1rem;

    .columns {
        margin: 0;
    }

    .column {
        padding: 0
    }

}

.column {
    padding: 0 0.75rem;
}

::v-deep {
    .b-tabs .tab-content {
        padding: 0
    }

    .tabs li.is-active a {
        font-weight: 600;
    }

    @media (max-width: 768px) {
        .b-table .table-wrapper.has-mobile-cards tr:not([class*=is-]) {
            background: #fff;
            border-radius: 6px;
        }

        .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td {
            border-bottom: none;
        }
    }
}
</style>