export const GuiderReportStatuses = [
    {
        id: 0,
        name:'Nothing to report',
        class: 'success'
    },

    {
        id: 1,
        name:'Was late',
        class: 'warning'
    },

    {
        id: 2,
        name:'No show',
        class: 'danger'
    },
    {
        id: 3,
        name:'Other'
    },

]